<template>
    <div class="card">
        <div class="card-content">
            <div class="content">
                <!--
                <b-field
                    grouped>
                    <b-field>
                        <b-checkbox
                            v-if="dataFromDB.sols.length > 0"
                            @input="updateMap(layers)"
                            v-model="layers.sols">
                            Sols
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox
                            v-if="dataFromDB.dataFromDB.length > 0"
                            @input="updateMap(layers)"
                            v-model="layers.habitats">
                            Habitats
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox
                            v-if="dataFromDB.ecobuages.length > 0"
                            @input="updateMap(layers)"
                            v-model="layers.ecobuages">
                            Ecobuages
                        </b-checkbox>
                    </b-field>
                </b-field>
                -->

                <!-- Placettes de suivi -->
                <b-field grouped>
                    <b-checkbox
                        v-model="mapLayers.placettes">
                        Placettes de suivi
                    </b-checkbox>
                    <b-select
                        placeholder="Année"
                        v-model="mapLayers.placettes_year">
                        <option value="%">Tout</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                    </b-select>
                </b-field>
                <!-- Couche écobuage -->
                <div class="block">
                <b-field>
                        <b-checkbox
                            v-model="mapLayers.ecobuage">
                            Écobuage / feu
                        </b-checkbox>

                    <b-select
                        placeholder="Année"
                        v-model="mapLayers.ecobuage_year">
                        <option value="%">Tout</option>
                        <option value="2017-2018">2017-2018</option>
                        <option value="2018-2019">2018-2019</option>
                        <option value="2019-2020">2019-2020</option>
                        <option value="2020-2021">2020-2021</option>
                        <option value="2021-2022">2021-2022</option>
                        <option value="2022-2023">2022-2023</option>
                        <option value="2023-2024">2023-2024</option>
                    </b-select>
                    <b-tooltip multilined label="Surfaces apparaissant comme brûlées, issues de l'analyse annuelle d'images satellites (novembre-juin). Ces feux peuvent avoir eu lie de façon de manière déclarée ou non">
                            &nbsp; <b-icon icon="information" size="is-small" type="is-primary"></b-icon>
                    </b-tooltip>
                </b-field>
            </div>
                <b-field>
                    <b-checkbox
                        v-model="mapLayers.obstecoul">
                        Obstacles à l'écoulement
                    </b-checkbox>
                </b-field>
                <div v-if="isAuth">
                    <p><b>Données sous convention</b></p>
                    <b-field v-for="layer in sig_convention_available"
                             v-bind:key="layer">
                        <b-checkbox
                            v-model="mapLayers.sig_convention"
                            :native-value="layer">
                            {{ layer }}
                        </b-checkbox>
                    </b-field>
                    <!-- Theme -->
                    <b-field label="Afficher les commentaires">
                        <b-taginput
                            v-model="themesToDisplay"
                            :data="filteredTags"
                            autocomplete
                            open-on-focus
                            field="label"
                            icon="label"
                            placeholder="Add a tag"
                            @typing="getFilteredTags">
                        </b-taginput>
                    </b-field>
                    <b-button
                        type="is-primary"
                        @click="getAllComments()"
                    >
                        Afficher
                    </b-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "LayersManager",
    props: {
        mapLayers: {},
        themes: Array,
        filteredTags: Array,
        isAuth: Boolean,
    },
    beforeMount() {
    },
    data() {
        return {
            themesToDisplay: [],
            sig_convention: [],
            sig_convention_available: [
                'cayolars',
                'chargement_2019',
                'cloturescsvb',
                'desserte_pistes_up',
                'equipements_pastoraux_n2k',
                'placettes_equarissage',
                'plantations',
                'sites_apiculteurs',
                'syndical',
                'up_agrege',
                'maec',
                'v1_anonymise'
            ],
        };
    },
    methods: {
        getFilteredTags(text) {
            this.filteredTags = this.themes.filter((option) => {
                return option.label
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
        getAllComments() {
            console.log(this.themesToDisplay)
            let t;
            if ( this.themesToDisplay.length > 0 ) {
                t = this.themesToDisplay.map(function (obj) {
                    return obj.id;
                });
            } else {
                t = 'all'
            }
            this.mapLayers.commentaires = t
        }
    }
}
</script>

<style scoped>

</style>
